import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
import { mapGetters } from 'vuex';
import * as assetsApi from '@api/assets';
export default {
  name: 'AssetsIn',
  mixins: [common],
  computed: {
    ...mapGetters('user', ['projectInfo'])
  },
  data() {
    return {
      sourceType: '',
      sourceTypeText: '',
      sourceId: '',
      sourceTitle: '',
      sourceTypeShow: false,
      sourceTypeList: [{
        value: 1,
        text: '采购入库'
      }, {
        value: 2,
        text: '调拨入库'
      }],
      orderListShow: false,
      orderList: []
    };
  },
  methods: {
    onSelectSourceType(item) {
      this.sourceType = item.value;
      this.sourceTypeText = item.text;
      this.sourceTypeShow = false;
    },
    onOrderSelect() {
      if (!this.sourceType) {
        this.$toast('请选择入库类型');
        return;
      }
      let {
        dcProjectId
      } = this.projectInfo;
      assetsApi.getStockInOrderList({
        sourceType: this.sourceType,
        projectId: dcProjectId
      }).then(res => {
        let list = res.data.data || [];
        for (let item of list) {
          item.sourceTitle2 = item.sourceTitle || item.sourceId;
        }
        this.orderList = list;
        if (!this.orderList.length) {
          this.$toast('暂无数据');
        } else {
          this.orderListShow = true;
        }
      });
    },
    onSelectOrder(item) {
      this.sourceId = item.sourceId;
      this.sourceTitle = item.sourceTitle || '';
      this.orderListShow = false;
    },
    onNext() {
      if (!this.sourceId) {
        this.$toast('请选择入库单据');
        return;
      }
      if (this.sourceType == 1) {
        this.$router.push({
          name: 'AssetsInDetail',
          query: {
            sourceId: this.sourceId,
            sourceTitle: this.sourceTitle,
            sourceType: this.sourceType
          }
        });
      } else {
        this.$router.push({
          name: 'AssetsInDetail2',
          query: {
            sourceId: this.sourceId,
            sourceTitle: this.sourceTitle,
            sourceType: this.sourceType
          }
        });
      }
    }
  }
};